import * as React from "react";

import Wrapper from "../components/globals/wrapper/wrapper";
import WrapperItem from "../components/globals/wrapper/wrapper-item";
import PageHeader from "../components/globals/header";
import Headline from "../components/globals/type/headline";
import Text from "../components/globals/type/text";

const exampleText =
  "investing in a happier future for business, people, and the planet.";

const exampleStyle = {
  height: "30vw",
  background: "gray",
  borderRadius: "2vw",
};

/**
 * StyleGuidePage Component
 */
export default function StyleGuidePage() {
  return (
    <>
      <PageHeader title="Style Guide" />
      <Wrapper>
        <WrapperItem colStart="start-rail" colEnd="end-rail">
          <Headline size="headline2" text={exampleText} chLength={15} />
          <Headline size="headline3" text={exampleText} chLength={15} />
          <Headline size="headline4" text={exampleText} chLength={15} />
          <Headline size="headline5" text={exampleText} chLength={15} />
          <Headline size="headline6" text={exampleText} chLength={15} />
          <Text size="eyebrow" text={exampleText} />
          <Text size="bodyLarge" text={exampleText} />
          <Text size="body" text={exampleText} />
        </WrapperItem>
        <WrapperItem colStart="start-rail" colEnd="mid" style={exampleStyle} />
        <WrapperItem colStart="mid" colEnd="end-rail" style={exampleStyle} />
        <WrapperItem colStart="start-outer" colEnd="mid" style={exampleStyle} />
        <WrapperItem colStart="mid" colEnd="end-outer" style={exampleStyle} />
        <WrapperItem
          colStart="start-edge"
          colEnd="end-edge"
          style={exampleStyle}
        />
      </Wrapper>
    </>
  );
}
