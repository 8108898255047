import React from "react";

/**
 * Local Styles/JS
 */
import Wrapper from "./wrapper/wrapper";
import WrapperItem from "./wrapper/wrapper-item";
import Headline from "./type/headline";

interface PageHeaderProps {
  title: string;
}

/**
 * PageHeader Component
 */
export default function PageHeader({ title }: PageHeaderProps) {
  const hiddenStyles = { opacity: 1, scale: 1, y: 0 };

  return (
    <>
      <Wrapper>
        <WrapperItem
          colStart="start-rail"
          colEnd="end-rail"
          hiddenStyles={hiddenStyles}
        >
          <Headline text={title} size="headline3" />
        </WrapperItem>
      </Wrapper>
    </>
  );
}
