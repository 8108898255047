import React, { FC } from "react";
import styled from "styled-components";
import { LazyMotion, m, domAnimation } from "framer-motion";
import ClientOnly from "@components/helpers/client-only";

interface HeadlineProps {
  as?: keyof JSX.IntrinsicElements;
  size?:
    | "display1"
    | "headline1"
    | "headline2"
    | "headline3"
    | "headline4"
    | "headline5"
    | "headline6";
  chLength?: number | null;
  className?: string;
  children?: any;
  text?: string;
  enableMotion?: boolean;
}

/**
 *
 * @param {String} str
 */
export function textToArray(str: string) {
  const regex = /([A-Za-z0-9\-%$#@!\-+_9&*,'’"")(<>.;\/]+)|([\s])/gm;
  const subst = `{ text: "$1$2" },`;
  const objectify = str.replace(regex, subst);
  const arrayify = `[${objectify}]`;
  try {
    eval(arrayify); // eslint-disable-line
  } catch (e) {
    console.error(e instanceof SyntaxError);
    return str;
  }
  return eval(arrayify); // eslint-disable-line
}

const Mask = styled(m.span)`
  white-space: break-spaces;
  position: relative;
  display: inline-block;
  will-change: transform, opacity;
  > span {
    display: inline-block;
    position: relative;
    padding: 0;
    margin: 0;
  }
`;

interface MotionWordProps {
  textString?: string;
}

function MotionWord({ textString }: MotionWordProps) {
  if (!textString) return null;
  const textArray = textToArray(textString);

  const textVariants = {
    pre: {
      opacity: 0,
      y: 10,
      x: 10,
    },
    active: {
      opacity: 1,
      y: 0,
      x: 0,
      transition: {
        ease: [0.1, 0.25, 0.3, 1],
      },
    },
  };

  const textReturn = textArray.map(({ text }: any, i: number): any => (
    <Mask key={i} variants={textVariants}>
      {text}
    </Mask>
  ));

  return textReturn;
}

const Headline: FC<HeadlineProps & React.HTMLAttributes<HTMLOrSVGElement>> = ({
  as: Tag = "p",
  size = "headline1",
  className = "",
  chLength = 20,
  enableMotion = false,
  children,
  text,
  ...rest
}) => {
  if (!text && !children) return null;

  const textContainerVariants = {
    pre: {},
    active: {
      transition: {
        staggerChildren: 0.045,
        ease: [0.1, 0.25, 0.3, 1],
      },
    },
  };

  return (
    <LazyMotion features={domAnimation}>
      <Tag
        className={`${className} ${size}`}
        style={{
          marginLeft: "-0.075em",
          maxWidth: chLength !== null ? `${chLength}ch` : "var(--ch-length)",
        }}
        {...rest}
      >
        {enableMotion ? (
          <ClientOnly>
            <Mask
              variants={textContainerVariants}
              initial="pre"
              whileInView="active"
              whileHover="active"
              data-text={text}
              viewport={{
                once: true,
              }}
            >
              <MotionWord textString={text} />
            </Mask>
          </ClientOnly>
        ) : (
          children || text
        )}
      </Tag>
    </LazyMotion>
  );
};

export default Headline;
